import React from "react";
import { Link } from "react-router-dom";


const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/Expozy-108019807443777/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/in/expozy-limited-623baa199/",
  },
  {
    icon: "fa-telegram",
    link: "https://t.me/expozy",
  }
];

const CopyRightThree = () => {
  return (
    <div className="row">
    
      <div className="col-lg-4 order-lg-4 mb-20 mobile-center">
        <ul
          className=" d-flex social-icon mobile-center"
        >
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
        {/* End .social-icon */}
      </div>
      <div className="col-lg-4 order-lg-4 mb-20 text-center">
       <h5 className="title"> <a href="https://expozy.com/contact" target="_blank" style={{textDecoration: "underline"}}>Contact</a></h5>
        {/* End .social-icon */}
      </div>
      <div className="col-lg-4 order-lg-4 mb-20">
        <p className="copyright text-center">
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            Expozy
          </a>{" "}
          inc.
        </p>
      </div>
    </div>
  );
};

export default CopyRightThree;
