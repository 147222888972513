import React from "react";
import { Link } from "react-router-dom";
import FormFooterSignup from "../form/FormFooterSignup";
import ExpozyLogo from "../../assets/images/media/logo-expo-red.png";

const FooterSeven = () => {
  return (
    <div className="row ">
     

      <div
        className="col-lg-12 col-md-12 mb-40 text-center"
       style={{marginTop: "45px"}}
      >
        <a href="https://expozy.com" style={{textDecoration: "underline"}}><h5 className="title">Back to expozy.com</h5></a>
      
      </div>

    </div>
  );
};

export default FooterSeven;
