import React from "react";
import { Link } from "react-router-dom";
import missingPostImage from "../../assets/images/media/Error_404-01.jpg";


const BlogStyle1 = (props) => {
  return (
    <div
      className="col-lg-4 col-md-6"
 
      key={props.id}
  
    >
      <div className="post-meta">
        {props.image !== undefined ? (
          <Link to={`/post/${props.id}`} className="title">
            <img
            src={props.image['url']}
            alt="media"
            className="image-meta"
          /> </Link>
        ) : (
          <Link to={`/post/${props.id}`} className="title">
          <img
          src={missingPostImage}
          alt="media"
          className="image-meta"
        /></Link>
        )}
        <div className="tag">
        </div>
        <h3>
          <Link to={`/post/${props.id}`} className="title">
            {props.title}
          </Link>
        </h3>
        <Link
          to={`/post/${props.id}`}
          className="read-more d-flex justify-content-between align-items-center"
        >
          <span>Read More</span>
          <i className="flaticon-right-arrow"></i>
        </Link>
      </div>
      {/* /.post-meta */}
    </div>
  );
};

export default BlogStyle1;
