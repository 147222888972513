import React from "react";
import { Link } from "react-router-dom";
import missingPostImage from "../../assets/images/media/Error_404-01.jpg";


const BlogRelatedPosts = (props) => {
  return (
    <div
      className="col-lg-4 col-md-6"
      key={props.id}
    >
      <div className="post-meta related-article-post-meta-padding-bottom">
        {props.image !== undefined ? (
          <img
            src={props.image['url']}
            alt="media"
            className="image-meta"
          />
        ) : (
          <img
          src={missingPostImage}
          alt="media"
          className="image-meta"
        />
        )}
        <div className="tag">
        </div>
        <h3>
          <a href ={`/post/${props.id}`} className="title">
            {props.title}
          </a>
        </h3>
        <a
          href={`/post/${props.id}`}
          className="read-more d-flex justify-content-between align-items-center"
        >
          <span>Read More</span>
          <i className="flaticon-right-arrow"></i>
        </a>
      </div>
      {/* /.post-meta */}
    </div>
  );
};

export default BlogRelatedPosts;
