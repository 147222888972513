import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import ExpozyLogo from "../../../assets/images/media/logo-expo-red.png";
import {ReactComponent as LoginIcon}  from "../../../assets/images/icon/login-icon.svg";
import close from "../../../assets/images/icon/close.svg";

const HeaderLandingWebsiteBuilder = () => {
  // For Mobile  menu
  const [isOpen, setIsOpen] = useState(false);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-seven bg-none fixed"
            : "theme-main-menu sticky-menu theme-menu-seven bg-none"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo order-lg-1">
            <a href="https://expozy.com">
              <img src={ExpozyLogo}  className="expozy-logo" />
            </a>
          </div>
          {/* End logo */}

          <div className="right-widget order-lg-3">
            <ul className="d-flex align-items-center">
             
              {/* End login */}
              <li>
                <div className="theme-btn-twelve d-none d-lg-block">

                <a href="https://expozy.com/pricing">
                  
                  Start Free Trial
                </a>
                </div>
              </li>
            </ul>
          </div>
          {/* End right-widget */}

          <nav
            id="mega-menu-holder"
            className="navbar navbar-expand-lg pl-xl-5 ml-xl-5 order-lg-2"
          >
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={["home", "feature", "p-tour", "feedback"]}
                    currentClassName="active"
                    offset={-90}
                  >

                  <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        Expozy
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                        <a href="https://expozy.com/platform" className="dropdown-item">
                          How it works
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://expozy.com/technology" className="dropdown-item">
                          Technology
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://expozy.com/enterprise" className="dropdown-item">
                          Enterprise
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://expozy.com/integrations" className="dropdown-item">
                          Integrations
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://expozy.com/roadmap" className="dropdown-item">
                          Roadmap
                        </a>
                      </li>
                    </ul>
                  {/* /.dropdown-menu */}
                     </li>

                    <li className="nav-item">
                      <a href="https://expozy.com/pricing" className="nav-link">
                        Pricing
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="https://expozy.com/about" className="nav-link">
                        About
                      </a>
                    </li>

                     <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        Partners
                      </a>
                      <ul className="dropdown-menu">
                          <li>
                            <a href="https://expozy.com/become-a-partner" className="dropdown-item" target="_blank">
                              Become a partner
                            </a>
                          </li>
                          <li>
                            <a href="https://expozy.com/partners-directory" className="dropdown-item" target="_blank">
                              View Partners
                            </a>
                          </li>
    
                    </ul>
                  {/* /.dropdown-menu */}
                     </li>

                   
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        Resources
                      </a>
                      <ul className="dropdown-menu">
                          <li>
                            <a href="https://blog.expozy.com" className="dropdown-item" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li>
                            <a href="https://wiki.expozy.com" className="dropdown-item" target="_blank">
                              Wiki
                            </a>
                          </li>
                          <li>
                            <a href="https://expozy.com/faq" className="dropdown-item" target="_blank">
                              FAQ
                            </a>
                          </li>
                    </ul>
                  {/* /.dropdown-menu */}
                     </li>

                     <li className="nav-item">
                      <a href="https://expozy.com/login" target="_blank" className="nav-link">
                        <LoginIcon className="login-icon-nav" />
                      </a>
                    </li>
      {/* End li */}
                  
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          {/* End Navbar */}
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <a href="https://expozy.com">
            <img src={ExpozyLogo} alt="brand" />
          </a>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src={close} alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav  main-side-nav font-gordita"
          items={["home", "feature", "p-tour", "feedback"]}
          currentClassName="active"
          offset={-90}
        >
           <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        Expozy
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                        <a href="https://expozy.com/platform" className="dropdown-item">
                          How it works
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://expozy.com/technology" className="dropdown-item">
                          Technology
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://expozy.com/enterprise" className="dropdown-item">
                          Enterprise
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://expozy.com/integrations" className="dropdown-item">
                          Integrations
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="https://expozy.com/roadmap" className="dropdown-item">
                          Roadmap
                        </a>
                      </li>
                    </ul>
                  {/* /.dropdown-menu */}
                     </li>

                    <li className="nav-item">
                      <a href="https://expozy.com/pricing" className="nav-link">
                        Pricing
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="https://expozy.com/about" className="nav-link">
                        About
                      </a>
                    </li>

                     <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        Partners
                      </a>
                      <ul className="dropdown-menu">
                          <li>
                            <a href="https://expozy.com/become-a-partner" className="dropdown-item" target="_blank">
                              Become a partner
                            </a>
                          </li>
                          <li>
                            <a href="https://expozy.com/partners-directory" className="dropdown-item" target="_blank">
                              View Partners
                            </a>
                          </li>
    
                    </ul>
                  {/* /.dropdown-menu */}
                     </li>

                   
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                        Resources
                      </a>
                      <ul className="dropdown-menu">
                          <li>
                            <a href="https://blog.expozy.com" className="dropdown-item" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li>
                            <a href="https://wiki.expozy.com" className="dropdown-item" target="_blank">
                              Wiki
                            </a>
                          </li>
                          <li>
                            <a href="https://expozy.com/faq" className="dropdown-item" target="_blank">
                              FAQ
                            </a>
                          </li>
                    </ul>
                  {/* /.dropdown-menu */}
                     </li>

                     <li className="nav-item">
                      <a href="https://expozy.com/login" target="_blank" className="nav-link">
                        <LoginIcon className="login-icon-nav" />
                      </a>
                    </li>
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default HeaderLandingWebsiteBuilder;
