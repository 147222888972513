import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import xicon from "../../assets/images/icon/x.svg";
import 'react-toastify/dist/ReactToastify.css';
const FormFooterSignup = (props) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(email);

     // POST request using fetch inside useEffect React hook
     const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'authentication':'basic dVXQqZFufU1XG7VVOk',
 
      },
      body: JSON.stringify({ "email": email, "newsletter-checkbox": "1" })
  };
  fetch('https://core.expozy.com/api/subscribe', requestOptions)
      .then(response =>response.json())
      .then(response => {
        if(response.status==1) {
          toast.success('Subscription successful.');
          setTimeout(()=>{
            props.close();
          }, 3000);
        }
        else {
          toast.error(response.response);
        }
      })  
     
  };

 
  return (
    <form onSubmit={handleSubmit} className="user-data-form bg-white">
      <div className="row align-items-center">
        <div className="col-10">
        <h4>Sign up for our  newsletter</h4>
        <p>We only send useful and meaninful email.</p>
        </div>
        <div className="col-2">
        <img src={xicon} style={{cursor: "pointer"}} onClick={props.close}/>

        </div>
      </div>
      <div className="input-group-meta mb-50">
      <input type="text" placeholder="Enter your email" onChange={(event)=> {
        setEmail(event.target.value) 
      }
      } />
      <button type="submit" className="theme-btn-thirteen mt-30 mb-50 f-none-m-auto">Sign UP</button>
      </div>
      <ToastContainer 
      position="top-right"
autoClose={3000}
closeButton={false}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover/>
    </form>
  );
};

export default FormFooterSignup;
