import React, { useState, useEffect } from "react";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderWebsiteBuilderLanding from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import BlogStyle1 from "../../../components/blog/BlogStyle1";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";

const BlogV1 = () => {
  useDocumentTitle("Expozy || Blog");

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});

  useEffect(() => {
    fetch("https://core.expozy.com/api/blogPosts", {
      headers: {
        'authentication': 'basic dVXQqZFufU1XG7VVOk',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(
        (data) => {
          setItems(data);
          setIsLoaded(true);
        },
        (error) => {
          setError(error);
          setIsLoaded(true);
        }
      ).catch(error => alert("error" + error))
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    console.log(items);
    return (
      <div className="main-page-wrapper">
        <div className="inner-page-white-nav-items">
      <HeaderWebsiteBuilderLanding  />
    </div>      
        {/* End Header */}

        {/* 	=============================================
              Fancy Hero One
          ==============================================  */}
        <div className="fancy-hero-one">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 m-auto">
                <h2 className="font-gilroy-bold">
                  View Expozy's latest news
                </h2>
              </div>
              <div className="col-lg-9 m-auto">
                <p className="font-rubik">
                  Stay up to date with what's happening around Expozy's vibrant ecosystem.
                </p>
              </div>
            </div>
          </div>
          <div className="bubble-one"></div>
          <div className="bubble-two"></div>
          <div className="bubble-three"></div>
          <div className="bubble-four"></div>
          <div className="bubble-five"></div>
          <div className="bubble-six"></div>
        </div>
        {/* /.fancy-hero-one */}

        {/* =====================================================
              Feature Blog One
          ===================================================== */}
        <div className="feature-blog-one blog-page-bg">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="container">
            <div className="row">

              {items.result.map(item => (
                <BlogStyle1
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  image={item.images[0]}
                  url={item.url}
                  tags={[item.tags]}
                  date-published={item.date_publish}
                  body={item.body} />
              ))}


            </div>
            {/* End .row */}
            
          </div>
        </div>
        {/* /.feature-blog-one */}

        <footer className="theme-footer-seven md-mt-100">
          <div className="lg-container">
            <div className="container inner-btn-black">
              <FooterSeven />
            </div>

            <div className="container">
              <div className="bottom-footer">
                <CopyRightThree />
              </div>
            </div>
          </div>
          {/* /.lg-container */}
        </footer>
        {/* /.theme-footer-one */}
      </div>
    );
  }
}



export default BlogV1;
