import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import HeaderWebsiteBuilderLanding from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import BlogDetailsForm from "../../../components/form/BlogDetailsForm";
import { useParams } from "react-router-dom";
import Shape1 from "../../../assets/images/shape/95.svg"
import Shape2 from "../../../assets/images/shape/96.svg"
import missingPostImage from "../../../assets/images/media/Error_404-01.jpg";
import BlogRelatedPosts from "../../../components/blog/BlogRelatedPosts";
import parse from 'html-react-parser';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon
} from "react-share";
import Newsletter from "../../../components/form/FormFooterSignup";
import { useModal } from 'react-hooks-use-modal';
import {decode} from 'html-entities';


const BlogDetails = () => {
  useDocumentTitle("Expozy || Blog");
  
  let { id } = useParams();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false
  });

  const [modalOpen, setModalOpen] = useState(false);
  const shareUrl = "" + items.url + "";
  const title = "" + items.title + "";
  



  if(!modalOpen){
    setTimeout(() => {
      open();
      setModalOpen(true);
    }, 60000);
  }
  

  useEffect(() => {
    fetch(`https://core.expozy.com/api/blogPosts/${id}`, {
      headers: {
        'authentication': 'basic dVXQqZFufU1XG7VVOk',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      dataType: "json"
    })
      .then(res => res.json())
      .then(
        (data) => {
          setItems(data);
          setIsLoaded(true);
        },
        (error) => {
          setError(error);
          setIsLoaded(true);
        }
      ).catch(error => alert("error" + error))
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
   
  return (
    <div>
      <div className="main-page-wrapper">
      <div className="inner-page-white-nav-items">
      <HeaderWebsiteBuilderLanding  />
    </div>       {/* End Header */}

      {/* =============================================
            Fancy Hero Two
        ==============================================  */}
      <div className="fancy-hero-five bg-white">
        <img
          src={Shape1}
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src={Shape2}
          alt="shape"
          className="shapes shape-two"
        />
        <div className="bg-wrapper ">
          <div className="container">
            <div className="col-lg-10 m-auto text-center">
              <h6 className="page-title">Our News</h6>
              <h1 className="heading">
                <span>
                  {decode(items.title)}

                  <img src="images/shape/line-shape-11.svg" alt="" />
                </span>
                
              </h1>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-two */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="blog-page-bg">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
              <div className="post-meta">
              {items.images[0]['url'] !== " " ? (
                    <img
                      src={items.images[0]['url']}
                      alt="media"
                      className="image-meta"
                    />
                  ) : (
                    <img
                    src={missingPostImage}
                    alt="media"
                    className="image-meta"
                  />
                  )}
                <div className="tag">{items.date_publish}</div>
                <h3 className="title">
                  {items.title}
                </h3>
               
                <p>
                { parse(items.body) }
                </p>
              
                <div className="d-sm-flex align-items-center justify-content-between share-area">
                  <ul className="tag-feature d-flex g-3px">
                    <li>Tags: &nbsp;</li>
                    {items.tags == 0 ? (
                     <li>There are no tags.</li>
                        
                    ): (

                      items.tags.map(item => (
                        <li key={item.id}>
                          <a href="#">{item}</a>
                      </li>
                    ))
                    )}
                  
                   
                    
                  </ul>
                  <ul className="share-option d-flex align-items-center">
                    <li>Share</li>
                    <li>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className="Demo__some-network__share-button"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>  
                    </li>
                    <li>
                      <LinkedinShareButton url={shareUrl} quote={title}
                        className="Demo__some-network__share-button" >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <TelegramShareButton url={shareUrl} quote={title}
                        className="Demo__some-network__share-button">

                        <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </li>
                    <li>
                      <TwitterShareButton  url={shareUrl} quote={title}
                        className="Demo__some-network__share-button">
                          <TwitterIcon size={32} round />
                          </TwitterShareButton>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /End post content  */}

       
              {/* /.comment-area */}

              <div className="container m0 ">
              <h3 className="title">Related Articles</h3>
                <p>
                  See some of the articles in the selected category
                </p>
                <div className="row">
               
                {items.related_posts.map(item => (
                  <BlogRelatedPosts
                    key={item.blog_id}
                    id={item.blog_id}
                    title={item.title}
                    url={item.url}
                    />
              ))}
                {/* /.form-style-light */}
              </div>
              </div>
              {/* /.comment-form-section */}
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
        
      {/* /.theme-footer-one */}
      </div>
      {/* /.feature-blog-one */}

      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
        <Modal>
          <Newsletter close={close} />
        </Modal>
    </div>
    </div>

    
  );
  
  
};
}

export default BlogDetails;
